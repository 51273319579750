import React, { useState } from "react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { fetchPost } from "./Utils/FetchApi";
import ApiNames from "./Constants/ApiNames";
import UserContext from "./Utils/User_Context";
import AwarenessScreen from "./Screens/AwarenessScreen";

const LandingRoute = () => {
  const [token, setToken] = useState("");

  useEffect(() => {
    GenerateToken();
  }, []);

  const GenerateToken = async (e) => {
    try {
      let data = {
        username: process.env.REACT_APP_UserName,
        password: process.env.REACT_APP_Password,
      };
      let response = await fetchPost(ApiNames.authenticateWeb, null, data);
      if (response.status === 0) {
        setToken(response.token);
      }
    } catch (err) {
      console.warn(err);
    }
  };
  return (
    <UserContext.Provider
      value={{
        token,
      }}
    >
      <Routes>
        <Route exact path="/awareness" element={<AwarenessScreen />}></Route>
      </Routes>
    </UserContext.Provider>
  );
};

export default LandingRoute;
